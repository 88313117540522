import Input from "../../../components/input";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserVirtualAccount,
  registerUser,
} from "../../../hooks/local/userReducer";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";

const CreateAccount = () => {
  document.title = "Create account - CloudVantage";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const createAcctForm = useFormik({
    initialValues: {
      fullname: "",
      emailadd: "",
      phonenumber: "",
      userpass: "",
      confirmPass: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Please provide your full name"),
      emailadd: Yup.string()
        .required("Please provide your email address")
        .email("Please enter a valid emaill address"),
      phonenumber: Yup.number()
        .required("Phone Number cannot be empty")
        .typeError("Invalid Phone Number Format")
        .max(9999999999, "Phone Number has to be 11 digit")
        .min(999999999, "Phone Number has to be 11 digit"),
      userpass: Yup.string()
        .required("Password cannot be empty")
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@.$!%#?&])[A-Za-z\d@.$!%*#?&]{8,}$/,
          "Please check password requirement information"
        ),

      confirmPass: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("userpass"), null], "Passwords must match"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { fullname, userpass, phonenumber, emailadd } = values;

      const { fullname: customerName, phonenumber: phoneNumber } = values;

      let virtualAccountData = {
        customerName,
        phoneNumber: `1${phoneNumber}`,
        mainPhoneNumber: "09069434255",
      };

      const { payload: virtAcctPayload } = await dispatch(
        createUserVirtualAccount(virtualAccountData)
      );

      if (virtAcctPayload.status_code === "200") {
        const accountNumber = virtAcctPayload?.data?.accountNumber;
        const accountName = virtAcctPayload?.data?.accountName;

        let registrationData = {
          fullname,
          userpass,
          phonenumber,
          emailadd,
          virt_acct_numb: accountNumber ? accountNumber : "",
          virt_acct_name: accountName ? accountName : "",
        };

        const { payload } = await dispatch(registerUser(registrationData));

        if (payload.statuscode === "00") {
          navigate("/sign-in");
          resetForm();
        }
      }

      if (virtAcctPayload.status_code === "400" || "") {
        let registrationData = {
          fullname,
          userpass,
          phonenumber,
          emailadd,
        };

        const { payload } = await dispatch(registerUser(registrationData));

        if (payload.statuscode === "00") {
          navigate("/sign-in");
          resetForm();
        }
      }
    },
  });
  return (
    <div className="px-4 flex justify-center items-center py-8">
      <Spinner loading={useSelector((state) => state.user).loading} />
      {/* Form */}
      <div className="my-3 w-full md:w-[60%]">
        <div className="bg-white dark:bg-[#141D26] border border-[#c4c4c432] dark:text-white rounded-md py-10 grid gap-4">
          <div className="px-6">
            <p className="pb-1 crimson-font text-lg font-semibold">Register</p>
            <p className="text-xs text-[#c4c4c4] pb-2 border-b">
              Create a CloudVantage account
            </p>
          </div>
          {/* Personal information */}
          <form onSubmit={createAcctForm.handleSubmit}>
            <div className="px-6 pb-4">
              <p className="text-[#1DA1F2] text-xs mb-4">
                1. Personal information
              </p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <Input
                  label={"Full name:"}
                  type={"text"}
                  name={"fullname"}
                  value={createAcctForm.values.fullname}
                  onChange={createAcctForm.handleChange}
                  onBlur={createAcctForm.handleBlur}
                  onError={
                    createAcctForm.touched.fullname &&
                    createAcctForm.errors.fullname
                      ? createAcctForm.errors.fullname
                      : null
                  }
                />
                <Input
                  label={"Email address:"}
                  type={"email"}
                  name={"emailadd"}
                  value={createAcctForm.values.emailadd}
                  onChange={createAcctForm.handleChange}
                  onBlur={createAcctForm.handleBlur}
                  onError={
                    createAcctForm.touched.emailadd &&
                    createAcctForm.errors.emailadd
                      ? createAcctForm.errors.emailadd
                      : null
                  }
                />
                <div className="lg:col-span-2">
                  <Input
                    label={"Phone number:"}
                    type={"tel"}
                    countryCodeDisplay={"true"}
                    placeholder={"e.g 0701 234 5678"}
                    name={"phonenumber"}
                    value={createAcctForm.values.phonenumber}
                    onChange={createAcctForm.handleChange}
                    onBlur={createAcctForm.handleBlur}
                    onError={
                      createAcctForm.touched.phonenumber &&
                      createAcctForm.errors.phonenumber
                        ? createAcctForm.errors.phonenumber
                        : null
                    }
                  />
                </div>
              </div>
            </div>
            {/* Account security */}
            <div className="px-6 pb-4">
              <p className="text-[#1DA1F2] text-xs mb-4">2. Account security</p>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <Input
                  label={"Password:"}
                  type={"password"}
                  isPassword={"true"}
                  placeholder={"*Required"}
                  name={"userpass"}
                  value={createAcctForm.values.userpass}
                  onChange={createAcctForm.handleChange}
                  onBlur={createAcctForm.handleBlur}
                  onError={
                    createAcctForm.touched.userpass &&
                    createAcctForm.errors.userpass
                      ? createAcctForm.errors.userpass
                      : null
                  }
                />

                <Input
                  label={"Confirm password:"}
                  type={"password"}
                  isPassword={"true"}
                  placeholder={"*Required"}
                  name={"confirmPass"}
                  value={createAcctForm.values.confirmPass}
                  onChange={createAcctForm.handleChange}
                  onBlur={createAcctForm.handleBlur}
                  onError={
                    createAcctForm.touched.confirmPass &&
                    createAcctForm.errors.confirmPass
                      ? createAcctForm.errors.confirmPass
                      : null
                  }
                />
              </div>
            </div>
            {/* Password verification */}
            <div className="px-6 pb-4 text-xs text-black/50 dark:text-white/50">
              <p className="mb-2">Your password must contain:</p>
              <ul className="list-inside list-disc gap-2 grid">
                <li className=""> at least 8 characters</li>
                <li className=""> at least 1 special character</li>
                <li className=""> at least 1 number</li>
                <li className=""> at least 1 uppercase alphabet</li>
              </ul>
            </div>
            {/* Submit */}
            <div className="grid md:flex md:justify-between gap-6 md:gap-0 items-center px-6 mt-6">
              <button
                type="submit"
                className="text-sm md:text-xs rounded text-white bg-[#1DA1F2] px-8 py-4 cursor-pointer"
              >
                Create an account
              </button>
              <Link
                to={"/sign-in"}
                className="text-sm md:text-xs text-center opacity-70 hover:text-[#1DA1F2] cursor-pointer"
              >
                Sign in instead
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
