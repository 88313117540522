import { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const currency = useSelector((state) => state.user.currency);
  const [cartNumber, setCartNumber] = useState(0);
  useEffect(() => {
    const cart = JSON.parse(sessionStorage.getItem("cart")) || [];
    if (cart && cart.dollarPrice && cart.nairaPrice) {
      let price = 0;
      if (currency === "USD") {
        price = cart.dollarPrice;
      } else if (currency === "NGN") {
        price = cart.nairaPrice;
      }
      setCartNumber(price);
    } else {
      setCartNumber(0);
    }
  }, [currency]);

  return (
    <CartContext.Provider value={{ cartNumber, setCartNumber }}>
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
