import { ReactComponent as BluePattern } from "../../assets/images/bluePattern.svg";
import Input from "../../components/input";
import Select from "../../components/select";
import TextArea from "../../components/textArea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { createContactSupport } from "../../hooks/local/userReducer";
import { useEffect, useState } from "react";

const Support = () => {
  document.title = "Support - CloudVantage";
  const options = [
    { value: "all-services", label: "All services" },
    { value: "payment-dispute", label: "Payment Dispute" },
    { value: "domain-hosting", label: "Domain Hosting" },
    { value: "vps", label: "Virtual Private Servers" },
    {
      value: "multipurpose-services",
      label: "Multipurpose Co-operative Society Services",
    },
    { value: "email-services", label: "Email services" },
  ];
  const [selectedService, setSelectedService] = useState(sessionStorage.getItem('supportType') || "");

  useEffect(() => {
    sessionStorage.setItem('supportType', selectedService); 
  }, [selectedService]);
  const dispatch = useDispatch();

  const createSupport = useFormik({
    initialValues: {
      service: selectedService,
      fullname: "",
      phone_number: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      service: Yup.string().required("Please select a service"),
      fullname: Yup.string().required("Full name is required"),
      phone_number: Yup.string().required("Phone number is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { payload } = await dispatch(
        createContactSupport({ createContactSupportData: values })
      );
      if (payload.statuscode === "00") {
        resetForm();
      }
    },
  });

  return (
    <div className="px-4 md:px-0 grid gap-4 lg:gap-16 pt-6 pb-10">
      <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10 md:py-16">
          <p className="text-lg leading-snug crimson-font font-bold mb-4">
            Support
          </p>
          <p className="text-sm">
            At CloudVantage, we offer the support and advice you need to turn
            your ideas into reality.
          </p>
        </div>
        <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
          <BluePattern className="w-full" />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        <div className="md:col-span-8">
          <p className="text-lg dark:text-white leading-snug crimson-font font-bold mb-4">
            Report a problem
          </p>
          <form
            onSubmit={createSupport.handleSubmit}
            className="grid grid-cols-1 md:grid-cols-2 gap-4 items-end"
          >
            <Select
              label="Service"
              name="service"
              options={options}
              value={selectedService}
              onBlur={createSupport.handleBlur}
              onChange={(e) => {
                setSelectedService(e.target.value);
                createSupport.setFieldValue("service", e.target.value);
              }}
              onError={
                createSupport.touched.service && createSupport.errors.service
              }
            />

            <Input
              label="Full name"
              name="fullname"
              value={createSupport.values.fullname}
              onBlur={createSupport.handleBlur}
              onChange={createSupport.handleChange}
              onError={
                createSupport.touched.fullname && createSupport.errors.fullname
              }
            />
            <Input
              label="Phone number"
              name="phone_number"
              value={createSupport.values.phone_number}
              onBlur={createSupport.handleBlur}
              onChange={createSupport.handleChange}
              onError={
                createSupport.touched.phone_number &&
                createSupport.errors.phone_number
              }
            />
            <Input
              label="Email address"
              name="email"
              value={createSupport.values.email}
              onBlur={createSupport.handleBlur}
              onChange={createSupport.handleChange}
              onError={
                createSupport.touched.email && createSupport.errors.email
              }
            />
            <TextArea
              label="Message"
              name="message"
              value={createSupport.values.message}
              onBlur={createSupport.handleBlur}
              onChange={createSupport.handleChange}
              onError={
                createSupport.touched.message && createSupport.errors.message
              }
            />
            <div className="mt-2 md:col-span-2">
              <button
                type="submit"
                className="bg-black dark:bg-[#1da1fc] text-white text-xs px-6 py-4 rounded-sm"
              >
                Send message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support;
