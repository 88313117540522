import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "../pages/dashboardLayout";
import MyCart from "../pages/cart";
import BuyVPS from "../pages/buyVPS";
import Help from "../../generalPages/loggedInSupport";
import BuyDomain from "../pages/buyDomain";
import Resources from "../pages/resources";
import Activity from "../pages/activity";
import Billing from "../pages/billing";
import Snapshot from "../pages/snapshot";
import ReservedIP from "../pages/reservedIP";
import LoadBalancer from "../pages/loadBalancers";
import ManageProject from "../pages/manageProject";
import Settings from "../pages/settings";
import FAQs from "../pages/faqs";
import ConfigureVPS from "../pages/configureVPS";
import { useDispatch, useSelector } from "react-redux";
import TopBar from "./topBar";
import SideBar from "./sideBar";
import UserSignOut from "../auth/Logout";
import Input from "../../../components/input";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextArea from "../../../components/textArea";
import {
  listUserProjects,
  updateUserProject,
} from "../../../hooks/local/userReducer";
import Spinner from "../../../components/spinner";
import SecurityGroups from "../pages/securityGroups";
import ManageSecurityGroups from "../pages/manageSecurityGroups";

const UserLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSessionData = useSelector((state) => state.user.userSessionData);
  const userProjectList = useSelector((state) => state.user.userProjectList);
  const [showDefaultProjectDiv, setDefaultProjectDiv] = useState(false);

  useEffect(() => {
    if (!userSessionData) {
      navigate("/sign-out");
    }
  });

  useEffect(() => {
    if (userProjectList.length > 0 && userSessionData.email) {
      const userDefaultProjectName = userProjectList[0].name;
      if (userDefaultProjectName === userSessionData.email) {
        setDefaultProjectDiv(true);
      }
    }
  }, [userProjectList, userSessionData, showDefaultProjectDiv]);

  const updateDefaultProject = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectId: userProjectList?.[0]?.id || "",
      projectDescription: "",
      projectName: "",
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Project Name cannot be empty"),
      projectDescription: Yup.string().required(
        "Project Description cannot be empty"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const { projectId, projectName, projectDescription } = values;
      let updateProjectData = {
        name: projectName,
        description: projectDescription,
        projectId,
      };
      const { payload } = await dispatch(
        updateUserProject({ updateProjectData, navigate })
      );
      if (payload.statuscode === "00") {
        await dispatch(listUserProjects());
        setDefaultProjectDiv(false);
        navigate("/client-area");
        resetForm();
      }
    },
  });

  return (
    <div className="grid items-start relative">
      <Spinner loading={useSelector((state) => state.user).loading} />
      {showDefaultProjectDiv && (
        <div className="fixed top-0 h-screen w-full z-50 bg-black/60 backdrop-blur-md flex items-center justify-center p-4">
          <div className="bg-white dark:bg-[#141D26] rounded-md p-4 md:p-10 w-full md:w-1/3">
            <p className="text-xl text-primaryBlue">
              Welcome to your CloudVantage Dashboard!
            </p>
            <p className="text-sm text-gray-500 mt-2">
              Your account comes with a default project. Update the details
              before continuing to your dashboard.
            </p>
            <form onSubmit={updateDefaultProject.handleSubmit}>
              <div className="grid gap-4 mt-8">
                <Input
                  label={"Project name"}
                  type={"text"}
                  name={"projectName"}
                  value={updateDefaultProject.values.projectName}
                  onBlur={updateDefaultProject.handleBlur}
                  onChange={updateDefaultProject.handleChange}
                  onError={
                    updateDefaultProject.errors.projectName &&
                    updateDefaultProject.touched.projectName
                      ? updateDefaultProject.errors.projectName
                      : null
                  }
                />
                <TextArea
                  label={"Project description"}
                  type={"text"}
                  noOfRow={"3"}
                  name={"projectDescription"}
                  value={updateDefaultProject.values.projectDescription}
                  onBlur={updateDefaultProject.handleBlur}
                  onChange={updateDefaultProject.handleChange}
                  onError={
                    updateDefaultProject.errors.projectDescription &&
                    updateDefaultProject.touched.projectDescription
                      ? updateDefaultProject.errors.projectDescription
                      : null
                  }
                />
                <div className="grid">
                  <button
                    type="submit"
                    className="text-sm md:text-xs opacity-70 text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer"
                  >
                    Update project details
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="w-full sticky top-0 z-40">
        <TopBar />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12">
        <SideBar />
        <div className="lg:col-span-10">
          <Routes>
            <Route path="/client-area" element={<Home />}>
              <Route path="" element={<Resources />} />
              {/* <Route path="activity" element={<Activity />} /> */}
              <Route path="snapshot" element={<Snapshot />} />
              <Route path="reservedIP" element={<ReservedIP />} />
              <Route path="manage" element={<ManageProject />} />
            </Route>
            <Route path="/billing" element={<Billing />} />
            <Route path="/cart" element={<MyCart />} />
            <Route path="/vps" element={<BuyVPS />} />
            <Route path="/securityGroups" element={<SecurityGroups />} />
            <Route
              path="/manageSecurityGroups/:id"
              element={<ManageSecurityGroups />}
            />
            <Route path="/loadBalancer" element={<LoadBalancer />} />
            <Route path="/domain" element={<BuyDomain />} />
            <Route path="/help" element={<Help />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/faq" element={<FAQs />} />
            <Route path="/configure-vps" element={<ConfigureVPS />} />
            <Route path="/sign-out" element={<UserSignOut />} />
            <Route path="*" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default UserLayout;
