import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { userClearLogOut, userLogOut } from "../../../hooks/local/userReducer";

const UserSignOut = () => {
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state)=>state.user.isAuthenticated);
    
    useEffect(()=>{
        dispatch(userLogOut());
        dispatch(userClearLogOut())
    },[dispatch]);

    if(!isAuthenticated){
        return <Navigate to={'/sign-in'}/>
    }


}
 
export default UserSignOut;
