import { Link } from "react-router-dom";
import InlineSpinner from "./buttonSpinner";

const Button = ({buttonText, width, destination, loading, buttonRole}) => {
    return ( 
      <div>
        {buttonRole === "submit" ?
          <button type="submit" disabled={loading} className="bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm"> 
              {loading ? 
              <div className="flex items-end">
                <InlineSpinner />
              </div>
              : 
              <div>
                <span>{buttonText}</span>
              </div>
              }
            </button>
        :
          <button className={`bg-[#1DA1F2] text-white text-sm px-6 py-4 rounded-sm ${width}`}>
              <Link to= {destination}>
                {buttonText}
              </Link>
          </button>
        }
      </div>
     );
}
 
export default Button;