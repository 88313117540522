import BlueImage from "../../assets/images/blueServer.png";
import search from "../../assets/svg-icons/search.svg";
import database from "../../assets/svg-icons/database.svg";
import optimized from "../../assets/svg-icons/optimized.svg";
import shield from "../../assets/svg-icons/shield.svg";
import VPS from "../../components/FAQs/vpsFAQ";
import Domain from "../../components/FAQs/domainFAQ";
import Email from "../../components/FAQs/EmailFAQ";
import VPN from "../../components/FAQs/vpnFAQ";
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import emailServicesBanner from "../../assets/images/email-illustration.svg";
import domainBanner from "../../assets/images/domain banner.svg";
import support from "../../assets/images/support phone.svg";
import cooperative from "../../assets/images/cooperative-illustration.svg";
import vpsIllustration from "../../assets/images/vps-illustration.svg";

const LandingPage = () => {
  document.title = "Welcome - CloudVantage"
  
  const [selectedOption, setSelectedOption] = useState('vps');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const idle = "py-4 px-6 text-xs cursor-pointer border border-[#00000030] dark:border-[#ffffff30] rounded-md text-[#00000080] dark:text-[#ffffff80]"
  const active = "m-0 py-4 px-6 text-xs cursor-pointer bg-[#1DA1F2] rounded-md text-white";

    return (
      <div>
        {/* hero section */}
        <div className="py-5 px-4">
          <p className="crimson-font text-xl font-semibold text-[#1DA1F2] capitalize">
            Discover your digital identity!
          </p>
          <p className="text-sm text-black/70 dark:text-white/80 mt-1">
            Elevate your online presence: Explore and secure the ideal domain
            for your cutting-edge services!
          </p>
          <div className="flex justify-between gap-4 mt-4">
            <input
              type="search"
              name=""
              id=""
              placeholder="Search for a domain..."
              className="bg-white text-black w-full p-4 text-sm rounded-sm border-0 active:outline-0 focus:outline-0 placeholder:opacity-70 placeholder:text-xs"
            />
            <Link to={"/search-domain"} className="">
              <button className="bg-[#1DA1F2] flex items-center gap-3 text-sm rounded-sm w-16 h-full md:px-6 md:w-full justify-center">
                <img src={search} alt="" className="md:hidden" />
                <span className="text-sm md:text-xs truncate text-center text-white hidden md:block">
                  Search domain
                </span>
              </button>
            </Link>
          </div>
        </div>

        <div className="px-4 grid grid-cols-1 lg:grid-cols-12 gap-4">
          <div className="lg:col-span-4 bg-brandBlue rounded-md p-6 md:p-10 grid gap-4 content-between">
            <p className="text-lg leading-snug crimson-font font-bold">Virtual Private Servers - Supercharge your VPS hosting with incredible speed and support</p>
            <img src={vpsIllustration} alt="" className="drop-shadow-sm"/>
            <div>
               <p className="text-sm mb-6">Unlock unparalleled performance with lightning-fast speed and unwavering support. Maximize efficiency, bolster security, and unleash innovation across your VPS hosting environment.</p>
                <Link to={"/sign-in"}>
                  <button className="bg-black text-white text-xs px-6 py-4 rounded-sm">
                     Set up your cloud account
                  </button>
                </Link>
            </div>
          </div>
          <div className="lg:col-span-8 grid gap-4">
            <div className="rounded-md p-6 md:p-10 bg-brandGreen grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="col-span-1 md:col-span-6 lg:col-span-7">
                <p className="text-lg leading-snug crimson-font font-bold">Domain Hosting - Transform your digital presence</p>
                <p className="text-sm mt-2">Tailored domain registration services to elevate your cloud experience!</p>

                <div className="text-[13px] mt-4 grid gap-4 text-xs">
                <div className="flex gap-2 items-center">
                  <img src={database} alt="" className="h-4" />
                  <span>99.9% uptime - guaranteed</span>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={optimized} alt="" className="h-4" />
                  <span>Optimized servers, up to 40% faster</span>
                </div>
                <div className="flex gap-2 items-center">
                  <img src={shield} alt="" className="h-4" />
                  <span>SSL certificates for all your websites</span>
                </div>
                </div>

                <div className="mt-6">
                <Link to={"/sign-in"}>
                  <button className="bg-black text-white text-xs px-6 py-4 rounded-sm">
                    Search domains
                  </button>
                </Link>
              </div>
              </div>
              <div className="col-span-1 md:col-span-6 lg:col-span-5 grid justify-items-stretch">
                <img src={domainBanner} alt="" className="justify-self-end" />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-brandRed rounded-md p-6 md:p-10 grid gap-4">
                <p className="text-lg leading-snug crimson-font font-bold">Multipurpose Co-operative Society Services</p>
                <img src={cooperative} alt="" className="drop-shadow-sm"/>
                <div className="mt-6">
                <Link to={"https://vantagecoops.com/members/opened/login.php"}>
                  <button className="bg-black text-white text-xs px-6 py-4 rounded-sm">
                    Get started
                  </button>
                </Link>
              </div>
              </div>
              <div className="bg-brandYellow rounded-md p-6 md:p-10 grid gap-4">
                <p className="text-lg leading-snug crimson-font font-bold">Email Services</p>
                <div className="">
                  <img src={emailServicesBanner} alt="" className="drop-shadow-xl"/>
                </div>
                <div>
               <p className="text-sm mb-6">Build trust with domain-based email
               addresses like you@yourdomain.com</p>
                <div>
                  <button className="bg-black text-white text-xs px-6 py-4 rounded-sm">
                    Coming soon...
                  </button>
                </div>
            </div>
              </div>
            </div>
          </div>
        </div>

        {/* Trusted experts */}
        <div className="bg-[#B3E0FF] py-20 mx-4 mt-4 rounded-md grid grid-cols-1 lg:grid-cols-2 items-center gap-8">
          <div className="px-10">
            <img src={BlueImage} alt="" />
          </div>
          <div className="text-[#263238] text-center lg:text-start p-4 md:p-10">
            <p className="text-lg leading-snug crimson-font font-bold">
              Trusted Experts In Making It Simple!
            </p>
            <p className="mt-2 text-sm mb-6">
              Unlock the potential of your cloud environments with our
              cutting-edge solutions. Effortlessly oversee your cloud
              infrastructure with the right tools and expertise.
            </p>
            <div className="flex justify-center lg:justify-start">
              <Link to={"/sign-in"}>
                <button className="bg-[#1DA1F2] text-white text-xs px-6 py-4 rounded-sm">
                  Get started
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* why choose us */}
        <div className="bg-gradient-to-r from-brandBlue to-brandGreen gap-6 px-16 py-6 mx-4 mt-4 rounded-md items-center grid grid-cols-1 md:grid-cols-3">
           <div className="md:col-span-2 order-2 md:order-1 text-center md:text-start mb-6 md:mb-0">
            <p className="text-lg leading-snug crimson-font font-bold">
              Why choose CloudVantage
            </p>
            <p className="mt-2 text-sm mb-8">
            Because we understand that the finest technology is only as effective as the team supporting it. That’s why we provide expert assistance every step of the way.
            </p>
            <Link to={"/sign-in"}>
                <button className="bg-black text-white text-xs px-6 py-4 rounded-sm">
                  Get started
                </button>
            </Link>
           </div>
           <div className="order-1 md:order-2">
             <img src={support} alt="" />
           </div>
        </div>

        {/* FAQs and footer */}
        <div className=" my-4 mx-4 rounded-md py-16 grid gap-8" id="faq">
          <div className="">
            <p className="mb-4 text-lg font-semibold text-center md:text-start crimson-font dark:text-white">
              Frequently Asked Questions.
            </p>
            <div className="grid grid-cols-2 text-center md:flex gap-2 mb-6">
              <span
                className={`${selectedOption === "vps" ? "active" + active : idle }`}
                onClick={() => handleOptionClick("vps")}
              >
                VPS
              </span>
              <span
                className={`${
                  selectedOption === "domain" ? "active" + active : idle
                }`}
                onClick={() => handleOptionClick("domain")}
              >
                Domains
              </span>
              <span
                className={`${
                  selectedOption === "email" ? "active" + active : idle
                }`}
                onClick={() => handleOptionClick("email")}
              >
                Email services
              </span>
              <span
                className={`${
                  selectedOption === "vpn" ? "active" + active : idle
                }`}
                onClick={() => handleOptionClick("vpn")}
              >
                VPN
              </span>
            </div>
            <div
              style={{ display: selectedOption === "vps" ? "block" : "none" }}
            >
              {" "}
              <VPS />{" "}
            </div>
            <div
              style={{
                display: selectedOption === "domain" ? "block" : "none",
              }}
            >
              {" "}
              <Domain />{" "}
            </div>
            <div
              style={{ display: selectedOption === "email" ? "block" : "none" }}
            >
              {" "}
              <Email />{" "}
            </div>
            <div
              style={{ display: selectedOption === "vpn" ? "block" : "none" }}
            >
              {" "}
              <VPN />{" "}
            </div>
          </div>
          <div className="px-4 md:px-16">
            <hr className="dark:opacity-10" />
          </div>
          <div className="px-4 md:px-16">
            <div className="text-center px-8">
              <p className="font-bold mb-2 crimson-font text-lg dark:text-white">
                Take your website online with CloudVantage Hosting
              </p>
              <p className="text-[13px] mb-5 dark:text-white/50">
                Enjoy special introductory pricing billed monthly
              </p>
              <Link to={"/sign-in"} className="flex justify-center">
                <button className="bg-[#1DA1F2] text-white flex items-center gap-3 text-sm px-6 py-4 rounded-sm">
                  Get started
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default LandingPage;