import React, { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Use local storage or default to a "light" theme if no preference is saved
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme ? savedTheme : "light";
  });

  useEffect(() => {
    // Apply the theme to the document class on load and when theme changes
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    // Store the theme preference in local storage
    localStorage.setItem("theme", theme);
  }, [theme]);

  // Manual theme switch only
  const handleThemeSwitch = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: handleThemeSwitch }}>
      {children}
    </ThemeContext.Provider>
  );
};







// import React, { createContext, useEffect, useState } from "react";

// export const ThemeContext = createContext();

// const getAutomaticTheme = () => {
//   const currentHour = new Date().getHours();

//   return currentHour >= 17 || currentHour < 7 ? "dark" : "light";
// };

// export const ThemeProvider = ({ children }) => {
//   const [theme, setTheme] = useState(() => {
//     const savedTheme = localStorage.getItem("theme");
//     return savedTheme || getAutomaticTheme();
//   });

//   useEffect(() => {
//     if (theme === "dark") {
//       document.documentElement.classList.add("dark");
//     } else {
//       document.documentElement.classList.remove("dark");
//     }

//     localStorage.setItem("theme", theme);
//   }, [theme]);

//   const handleThemeSwitch = (newTheme) => {
//     setTheme(newTheme);
//   };

//   useEffect(() => {
//     const checkTimeAndUpdateTheme = () => {
//       const automaticTheme = getAutomaticTheme();
//       setTheme(automaticTheme);
//     };

//     const interval = setInterval(checkTimeAndUpdateTheme, 15 * 60 * 1000);
    
//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <ThemeContext.Provider value={{ theme, setTheme: handleThemeSwitch }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };
