import { useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import {
  createSessionVirtualAccount,
  createUserVirtualAccount,
  getUserVirtualAccount,
} from "../../../hooks/local/userReducer";
import { CartContext } from "../../../components/cartContext";
import edit from "../../../assets/svg-icons/edit.svg";
import dewImage from "../../../assets/svg-icons/dewImage.svg";
import arrow from "../../../assets/svg-icons/blueArrow.svg";

const Home = () => {
  const { setCartNumber } = useContext(CartContext);
  const selectedProject = useSelector((state) => state.user.selectedProject);
  const { virtualAccount = {} } = useSelector((state) => state.user);
  const userSessionData = useSelector((state) => state.user.userSessionData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserVirtualAccount());
  }, [dispatch]);

  const walletBalance = virtualAccount ? virtualAccount.balance : null;
  const accountName = virtualAccount ? virtualAccount.acct_name : null;
  const accountNumber = virtualAccount ? virtualAccount.acct_num : null;

  document.title = "Client area - CloudVantage";

  const onSubmit = async () => {
    const name = userSessionData?.fullname;
    const phone = userSessionData?.phone;

    let virtualAccountData = {
      customerName: name,
      phoneNumber: `1${phone}`,
      mainPhoneNumber: "09069434255",
    };

    const { payload: virtAcctPayload } = await dispatch(
      createUserVirtualAccount(virtualAccountData)
    );

    if (virtAcctPayload.status_code === "200") {
      const accountNumber = virtAcctPayload?.data?.accountNumber;
      const accountName = virtAcctPayload?.data?.accountName;

      let sessionVirtualAccountData = {
        acct_num: accountNumber ? accountNumber : "",
        acct_name: accountName ? accountName : "",
        pref_deno: "naira",
      };

      const { payload } = await dispatch(
        createSessionVirtualAccount(sessionVirtualAccountData)
      );
      if (payload.statuscode === "00") {
        dispatch(getUserVirtualAccount());
      }
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12">
      <div className="px-4 md:px-10 py-6 lg:col-span-12 min-h-screen">
        <div className="grid md:flex gap-4 md:justify-between md:items-start pb-6">
          <div className="flex gap-3">
            <div className="bg-brandBlue dark:bg-brandBlue/10 rounded-md w-20 flex items-center justify-center">
              <img src={dewImage} alt="" className="h-5" />
            </div>
            <div className="dark:text-white text-sm">
              <p className="text-gray-500">
                {selectedProject ? selectedProject.name : ""}
              </p>
              <p className="mb-3">
                {selectedProject ? selectedProject.description : ""}
              </p>
              <div className="flex gap-2 items-center text-primaryBlue">
                <span>
                  <img src={edit} alt="" className="h-[18px]" />
                </span>
                <Link to={"/client-area/manage"}>Manage project</Link>
              </div>
            </div>
          </div>
          {accountName || accountNumber ? (
            <div className="text-sm">
              <p className="mb-1 dark:text-white">Account Details</p>
              <p className="text-gray-500 mb-1">Bank Name: Providus Bank</p>
              <p className="text-gray-500 mb-1">
                Account Name{accountName ? `: ${accountName}` : ""}
              </p>
              <p className="text-gray-500 mb-1">
                Account Number{accountNumber ? `: ${accountNumber}` : ""}
              </p>
            </div>
          ) : (
            ""
          )}

          <div>
            <span className="w-fit border border-[#c4c4c450] dark:border-[#c4c4c416] rounded-md p-3 flex items-center divide-x dark:divide-gray-500 mb-3">
              <span className="text-xs text-[#1da1f2] cursor-default pe-2">
                {` Wallet balance:${
                  walletBalance || walletBalance === 0
                    ? ` ${walletBalance}.00`
                    : " 0.00"
                }  `}
              </span>
              <span className="ps-2">
                <img src={arrow} alt="" />
              </span>
            </span>
          </div>
        </div>
        <div className="border-t border-t-[#c4c4c460] pt-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
