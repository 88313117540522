import Input from "../../../components/input";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../components/spinner";
import { Link } from "react-router-dom";
import { listUserProjects, userAuthenticate } from "../../../hooks/local/userReducer";
import Button from "../../../components/button";

const SignIn = () => {
  document.title = "Sign in - CloudVantage";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector((state) => state.user.loading);

  const signInForm = useFormik({
    initialValues: {
      emailAddress: "",
      userPassword: "",
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .required("Please provide your email address")
        .email("Please provide your email address"),
      userPassword: Yup.string().required("Password cannot be empty"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const { emailAddress, userPassword } = values;
      let signInData = { email:emailAddress, password:userPassword};
      const { payload } = await dispatch(userAuthenticate(signInData));
      if (payload.statuscode === "00") {
        await dispatch(listUserProjects());
        navigate("/client-area");
        resetForm();
      }
    },
  });

  return (
    <div className="px-4 flex justify-center items-center py-8">
      <Spinner loading={useSelector((state) => state.user).loading} />
      {/* Form */}
      <div className="my-3 w-full md:w-[50%] lg:w-[40%] ">
        <form onSubmit={signInForm.handleSubmit}>
          <div className="bg-white dark:bg-[#141D26] border border-[#c4c4c432] dark:text-white rounded-md px-6 py-10 grid gap-6">
            <div>
              <p className="pb-1 crimson-font text-lg font-semibold">Sign in</p>
              <p className="text-xs text-[#c4c4c4] pb-2 border-b dark:border-b-[#c4c4c432]">
                This page is restricted
              </p>
            </div>
            <Input
              label={"Email address"}
              type={"email"}
              name={"emailAddress"}
              value={signInForm.values.emailAddress}
              onChange={signInForm.handleChange}
              onBlur={signInForm.handleBlur}
              onError={
                signInForm.touched.emailAddress && signInForm.errors.emailAddress
                  ? signInForm.errors.emailAddress
                  : null
              }
            />
            <div>
              <Input
                label={"Password"}
                type={"password"}
                isPassword={"true"}
                name={"userPassword"}
                value={signInForm.values.userPassword}
                onChange={signInForm.handleChange}
                onBlur={signInForm.handleBlur}
                onError={
                  signInForm.touched.userPassword && signInForm.errors.userPassword
                    ? signInForm.errors.userPassword
                    : null
                }
              />
              <Link to={"/password-reset-link"} className="text-sm md:text-xs text-[#1DA1F2] cursor-pointer">Forgot password?</Link>
            </div>
            {/* Submit */}
            <div className="grid md:flex md:justify-between gap-6 md:gap-0 items-center">
              {/* <button type="submit" className="text-sm md:text-xs text-white bg-[#1DA1F2] rounded px-8 py-4 cursor-pointer">
                Sign in
              </button> */}
              <Button buttonRole={"submit"} loading={loading} buttonText={"Sign in"}/>
              <Link to={"/create-account"} className="text-sm md:text-xs text-center opacity-70 hover:text-[#1DA1F2] cursor-pointer">Create an account</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
