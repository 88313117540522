// import { useEffect, useState } from "react";
import cpu from "../assets/svg-icons/cpu-icon.svg";
import ram from "../assets/svg-icons/ram.svg";
import disk from "../assets/svg-icons/disk.svg";
import whiteDisk from "../assets/svg-icons/whiteDisk.svg";
import whiteRAM from "../assets/svg-icons/whiteRAM.svg";
import whiteCPU from "../assets/svg-icons/whiteCPU.svg";
import arrow from "../assets/svg-icons/smallWhiteArrow.svg";
import deleteIcon from "../assets/svg-icons/delete.svg";

const Flavours = ({
  name,
  price,
  vcpu,
  ramSize,
  diskSize,
  addToCart,
  removeFromCart,
  productId,
  isInCart,
  nairaPrice,
  dollarPrice,
  flavorId,
}) => {
  const handleAddToCart = () => {
    addToCart({
      name,
      price,
      vcpu,
      ramSize,
      diskSize,
      productId,
      nairaPrice,
      dollarPrice,
      flavorId,
    });
  };

  const handleRemoveFromCart = () => {
    removeFromCart(productId, flavorId);
  };

  return (
    <div className="border border-[#c4c4c450] dark:border-[#c4c4c416] dark:text-white rounded p-6 hover:bg-white dark:hover:bg-[#ffffff08] cursor-default">
      <p className="font-medium text-sm mb-2 text-black/50 dark:text-white/50">
        {name}
      </p>
      <p className="crimson-font text-[#1da1fc]">
        <span className="font-semibold text-xl">{price}</span>
        <span className="text-sm font-normal text-black/50 dark:text-white/50">
          /month
        </span>
      </p>
      <div className="text-xs mt-2 grid gap-4">
        <div className="flex gap-2 items-center">
          <img src={cpu} alt="" className="h-5 dark:hidden" />
          <img src={whiteCPU} alt="" className="h-5 hidden dark:block" />
          <span>VCPUs: {vcpu}</span>
        </div>
        <div className="flex gap-2 items-center">
          <img src={ram} alt="" className="h-5 dark:hidden" />
          <img src={whiteRAM} alt="" className="h-5 hidden dark:block" />
          <span>RAM: {ramSize}</span>
        </div>
        <div className="flex gap-2 items-center">
          <img src={disk} alt="" className="h-5 dark:hidden" />
          <img src={whiteDisk} alt="" className="h-5 hidden dark:block" />
          <span>Root disk: {diskSize}</span>
        </div>
      </div>
      <div className="mt-5 text-sm">
        {isInCart ? (
          <div
            className="bg-red-700 w-full p-4 text-white rounded flex justify-between items-center text-xs cursor-pointer"
            onClick={handleRemoveFromCart}
          >
            <span>Remove from cart</span>
            <img src={deleteIcon} alt="" className="h-5" />
          </div>
        ) : (
          <div
            className="bg-[#1DA1F2] w-full p-4 text-white rounded flex justify-between items-center text-xs cursor-pointer"
            onClick={handleAddToCart}
          >
            <span>Add to cart</span>
            <img src={arrow} alt="" className="h-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Flavours;
