import { CircularProgress } from "@mui/material";
import * as React from "react";
const InlineSpinner = ({ loading }) => {
  return (
    <div>
        <CircularProgress color="inherit" size={16} sx={{ pb: 0 }}/>
    </div>
  );
};

export default InlineSpinner;
