import { useSelector } from "react-redux";
import Flavours from "../../../components/flavours";
import React, { useState, useContext } from "react";
import { CartContext } from "../../../components/cartContext";
import pattern from "../../../assets/images/bluePattern.svg";
import value from "../../../assets/svg-icons/value.svg";
import performance from "../../../assets/svg-icons/performance.svg";
import flexible from "../../../assets/svg-icons/flexible.svg";
import security from "../../../assets/svg-icons/security.svg";
import support from "../../../assets/svg-icons/support.svg";
import { useListDews } from "../layout/resuableEffect";
import Spinner from "../../../components/spinner";

const BuyVPS = () => {
  document.title = "Buy VPS - CloudVantage";
  const currency = useSelector((state) => state.user.currency);
  const { setCartNumber } = useContext(CartContext);
  const vpsList = useListDews();
  const vpsMapList = vpsList.results || [];
  const [currentCartItemId, setCurrentCartItemId] = useState(null);

  const formatName = (name) => {
    return name
      .split(".")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  const addToCart = (flavor) => {
    const price = flavor.price;
    sessionStorage.setItem("cart", JSON.stringify([flavor]));
    setCartNumber(price);
    setCurrentCartItemId(flavor.productId);
  };

  const removeFromCart = () => {
    sessionStorage.removeItem("cart");
    setCartNumber(0);
    setCurrentCartItemId(null);
  };

  return (
    <div className="px-4 md:px-10 py-6 min-h-screen grid">
      <Spinner loading={useSelector((state) => state.user).loading} />
      {/* <div className="bg-brandBlue rounded-md grid grid-cols-1 lg:grid-cols-5">
        <div className="lg:col-span-3 px-6 md:px-10 py-10">
          <p className="text-lg leading-snug crimson-font font-bold mb-4 w-full lg:w-[70%]">
            <span className="text-primaryBlue">Virtual Private Servers:</span>{" "}
            <br />
            Supercharge your VPS hosting with incredible speed and support.
          </p>
          <p className="text-sm">
            Unlock unparalleled performance with lightning-fast speed and
            unwavering support. Maximize efficiency, bolster security, and
            unleash innovation across your VPS hosting environment.
          </p>
          <div className="mt-4">
            <p className="font-medium text-[13px] text-gray-600">Starts at:</p>
            <p className="font-semibold text-2xl crimson-font text-[#1da1fc]">
              &#x20A6; 0.00{" "}
              <span className="text-sm font-normal text-gray-600"> /month</span>
            </p>
            <button className="bg-black text-white text-xs px-6 py-4 rounded-sm mt-3">
              Get started
            </button>
          </div>
        </div>
        <div className="lg:col-span-2 flex items-end md:h-32 lg:h-full overflow-clip">
          <img src={pattern} alt="" className="w-full" />
        </div>
      </div> */}
      
      <div>
        <div>
          <p className="dark:text-white">Create a Virtual Instance:</p>
          <p className="text-black/40 dark:text-white/40 mb-4 text-sm">
            Select dew
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {vpsMapList.length > 0 &&
            vpsMapList.map((val, key) => {
              const price =
                currency === "NGN"
                  ? val.naira_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "NGN",
                    })
                  : val.dollar_price.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    });
              return (
                <Flavours
                  productId={val.id}
                  name={formatName(val.name)}
                  price={price}
                  keys={key}
                  nairaPrice={val.naira_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "NGN",
                  })}
                  dollarPrice={val.dollar_price.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  vcpu={val.vcpus}
                  ramSize={val.ram}
                  diskSize={val.disk}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                  isInCart={currentCartItemId === val.id}
                  flavorId={val.flavor_id}
                />
              );
            })}
        </div>
      </div>

      {/* <div>
        <p className="dark:text-white mb-4">Why choose our VPS:</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="bg-brandGreen rounded-md px-6 py-10">
            <img src={performance} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full">
              {" "}
              Performance{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Our VPS plans are optimized to deliver exceptional speed and
              reliability, leveraging advanced hardware and SSD storage to
              ensure fast load times and smooth operations. With dedicated
              resources, your applications will perform consistently, even under
              high traffic conditions.{" "}
            </p>
          </div>
          <div className="bg-brandBlue rounded-md px-6 py-10">
            <img src={flexible} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Flexibility{" "}
            </p>
            <p className="text-sm leading-6">
              Easily scale your resources as your business grows. Our VPS plans
              allow for seamless upgrades of CPU, RAM, and storage without
              downtime, adapting to your increasing demands. Start small and
              scale up effortlessly to meet your evolving needs.{" "}
            </p>
          </div>
          <div className="bg-brandRed rounded-md px-6 py-10 col-span-1 md:col-span-2 lg:col-span-1">
            <img src={security} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Security{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              We prioritize the security of your data and applications with
              robust measures like firewalls, DDoS protection, and regular
              updates. Automated backups and recovery solutions ensure your data
              is safe and can be restored quickly in case of an emergency.{" "}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <div className="bg-brandYellow rounded-md px-6 py-10">
            <img src={support} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Support{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Our dedicated support team is available 24/7 to assist you with
              any issues or questions. From setup and configuration to
              troubleshooting and optimization, our experts provide timely and
              effective support to ensure your VPS runs smoothly at all times.{" "}
            </p>
          </div>
          <div className="bg-brandGreen rounded-md px-6 py-10">
            <img src={value} alt="" class="h-5" />
            <p className="leading-snug crimson-font font-bold mb-3 mt-4 w-full lg:w-[70%]">
              {" "}
              Value{" "}
            </p>
            <p className="text-sm leading-6">
              {" "}
              Enjoy competitive pricing without compromising on quality. Our
              range of VPS plans offers cost-effective solutions tailored to fit
              different needs and budgets, delivering high performance and
              reliability at a great value.{" "}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default BuyVPS;
